import React, { Component } from 'react';
import './Publication.css';

class Publication extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0
    }
  }

  render() {
    const publication = this.props.publication;
    const index = this.props.index;
    return (
      <div>
        <div className="card publication-card">
          <div className="row g-0">
            <div className="col-sm-2 d-flex justify-content-center align-items-center">
              <img src={publication.coverImageUrl} className="card-img-top img-fluid" alt="..." style={{maxHeight:"200px", width: "auto"}}/>
            </div>
            <div className="col-sm-10">
              <div className="card-body m-1 border-radius-5 ps-4 pe-4">
                <div className="card-title">
                  <p className='text-secondary'>{publication.authors} <i className='fs-6 text-dark text-secondary-emphasis font-garamond fw-semibold'>{publication.name}</i>. {publication.description}</p>
                </div> 
                {/* <p className="card-text text-secondary">{publication.subText}</p> */}
                <div className='card-text' style={{ display: "flex", textAlign: "start" }}>
                  <div>
                    {/* <span className="iconify" data-icon="noto:round-pushpin"></span> */}
                    <p className="text-secondary ps-2 pe-2">{publication.subText.slice(0, 3)}</p>
                  </div>
                  <div>
                    <p className="text-align-justify text-secondary">{publication.subText.slice(3,)}</p>
                  </div>
                </div>
                <div className="d-flex text-primary-emphasis">
                  <h5>{publication.publisher}</h5>
                  <h4 className="ps-2"><span className="iconify" data-icon={publication.icon}></span></h4>
                </div>
                <a href={publication.doi} target='_blank' rel="noreferrer" className="btn btn-secondary stretched-link">View Publication</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Publication;
