import React, { Component } from 'react';
import './Recognition.css';

class Recognition extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0
    }
  }

  render() {
    const recognition = this.props.recognition;
    const index = this.props.index;
    return (
      <div className = {(index === 0 ? "":"pt-3")}>
        <div className='card recognition-card'>
          <div className="row g-0">
            <div className="col-sm-3 d-flex justify-content-center align-items-center">
              <img src={require(`../../assets/images/${recognition.coverImageUrl}`)} className="card-img-top img-fluid" alt="..." style={{ maxHeight: "200px", width: "auto" }} />
            </div>
            <div className="col-sm-9">
              <div className="card-body m-1 ps-4 pe-4 border-radius-5">
                <div className="card-title">
                  <h5 className='text-primary-emphasis'>{recognition.name}</h5>
                </div>
                {/* <p className="card-text text-secondary">{recognition.subText}</p> */}
                <div className='card-text' style={{ display: "flex", textAlign: "start" }}>
                  <div>
                    {/* <span className="iconify" data-icon="noto:round-pushpin"></span> */}
                    <p className="text-secondary pe-2">{recognition.subText.slice(0, 3)}</p>
                  </div>
                  <div>
                    <p className="text-align-justify m-auto text-secondary">{recognition.subText.slice(3,)}</p>
                  </div>
                </div>
                <div className="d-flex text-secondary pb-2">
                  <h6 className='m-auto ms-0 me-0'>Issued by:</h6>
                  <h2 className="ps-2 mb-0"><span className={"iconify "+ recognition.style} data-icon={recognition.icon}></span></h2>
                </div>
                <a href={recognition.url} target='_blank' rel="noreferrer" className="btn btn-secondary stretched-link">View Recognition</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Recognition;
