import React, { Component } from "react";
import "./Intro.css";
import IntroLogo from "../../components/IntroLogo/IntroLogo";
import Home from "../home/Home";

function LoadingLogo() {
  return (
    <div className="intro-logo-wrapper">
      <div className="intro-screen">
        <IntroLogo id="intro-logo"/>
      </div>
    </div>
  );
}

class Intro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadIntro: true,
    };
  }

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ loadIntro: false }), 4000);
  }

  componentWillMount() {
    clearTimeout(this.id);
  }

  render() {
    return this.state.loadIntro ? <LoadingLogo /> : <Home />;
  }
}

export default Intro;
