import React, { Component } from "react";
import "./PublicationCard.css";
import { publications } from "../../props";
import { Accordion } from "baseui/accordion";
import Publication from "../../components/publication/Publication";

class PublicationCard extends Component {
  render() {
    return (
      <div className="publication-accord">
        <Accordion>
          {publications.data.map((publication, index) => {
            return <Publication publication={publication} index={index} />
          })}
        </Accordion>
      </div>
    );
  }
}

export default PublicationCard;
