import React, { useState, useEffect, useRef } from "react";
import "./TextTruncate.css";

// create a functional component
export default function TextTruncate(props) {
  // create a state variable to store the visibility of the text
  const [showMore, setShowMore] = useState(false);

  // create refs to access the paragraph and the link elements
  const paraRef = useRef(null);
  const linkRef = useRef(null);

  // use the useEffect hook to add or remove the line-clamp class based on the state
  useEffect(() => {
    if (showMore) {
      // remove the line-clamp class
      paraRef.current.classList.remove("line-clamp-4");
      // change the link text to show less
      linkRef.current.textContent = "show less..";
    } else {
      // add the line-clamp class
      paraRef.current.classList.add("line-clamp-4");
      // change the link text to show more
      linkRef.current.textContent = "show more..";
    }
  }, [showMore]); // run the effect only when the showMore state changes

  // create a toggle function to change the state
  const toggleShowMore = (event) => {
    event.preventDefault();
    setShowMore((prev) => !prev);
  };

  // return the JSX code
  return (
    <div className="">
      <p className="line-clamp-4" ref={paraRef}>
        {props.text}
      </p>
      <a className="more text-secondary" href="#" onClick={toggleShowMore} ref={linkRef}>
        more..
      </a>
    </div>
  );
}