import React, { Component } from 'react';
import './Education.css';

class Education extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0
    }
  }
  componentDidMount() {
    this.setState({ height: this.divElement.clientHeight });
  }
  render() {
    const degree = this.props.degree;
    const index = this.props.index;
    return (
      <div className="education-list-item" ref={(divElement) => { this.divElement = divElement }} style={{ marginTop: (index === 0 ? 30 : 0) }}>
        <div style={{ display: "contents" }}>
          <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
            <div className="education-card mb-3 mx-sm-2 p-1 bg-tertiary" style={{ flexGrow: 1 }}>
              <div>
                <h4 className="education-card-title  px-2 text-success education-text-gradient">{degree["title"]}</h4>
                <div className="education-card-header" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div>
                    <div>
                      <p className="education-card-company  px-2 text-secondary">
                        <p className='text-secondary mb-1'>{degree["subtitle"]}</p>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="education-card-heading-right px-2">
                      <p className="education-card-duration">{degree["duration"]}</p>
                      <p className="education-card-location">{degree["location"]}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='custom-card-body p-3 mt-2'>
                {degree.descriptions.map((sentence) => {
                  return (
                    <div style={{display: "flex", textAlign:"start"}}>
                      <div>
                        {/* <span className="iconify" data-icon="noto:round-pushpin"></span> */}
                        <p>{sentence.slice(0,2)}</p>
                      </div>
                        <div>
                        <p className="text-align-justify text-secondary-emphasis">{sentence.slice(3,)}</p>
                        </div>
                    </div>
                  );
                })}
              </div>

            </div>

          </div>
        </div>
      </div>

    );
  }
}

export default Education;
