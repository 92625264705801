import React, { Component } from "react";
import "./SkillsCard.css";
import { skills } from "../../props";
import Skills from "../../components/skills/Skills";

class SkillsCard extends Component {
  render() {
    return (
      <div className="row mb-md-0">
          {skills.data.map((skill, index) => {
            return <Skills skill={skill} index={index} />
          })}
      </div>
    );
  }
}

export default SkillsCard;