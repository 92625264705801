import React, { Component } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Intro from "../pages/intro/Intro";

export default class Main extends Component {
  render() {
    return (
      <div>
        <Router basename="/">
          <Routes>
            <Route
              path="/*"
              exact
              element={
                <Intro />
              }
            />
          </Routes>
        </Router>
      </div>
    );
  }
}