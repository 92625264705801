import React, { Component } from 'react';
import "./Home.css";
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import svg from "../../assets/svg/coding.svg";
import ReactRotatingText from "react-rotating-text";
import ExperienceCard from '../../containers/ExperienceCard/ExperienceCard';
import EducationCard from '../../containers/EducationCard/EducationCard';
import SkillsCard from '../../containers/SkillsCard/SkillsCard';
import PublicationCard from '../../containers/PublicationCard/PublicationCard';
import RecognitionCard from '../../containers/RecognitionCard/RecognitionCard';
import bgsvg from "../../assets/svg/bg.svg";

class Home extends Component {
  componentDidMount() {
    const myDiv = document.getElementById('skillsID');
    const height = myDiv.offsetHeight+300;
    // console.log(`viewport height`+window.innerHeight);
    // console.log(`skill div height:`+height);
    if(window.innerHeight>height) myDiv.classList.add("sticky-skills");
  }

  componentDidUpdate() {
    const myDiv = document.getElementById('skillsID');
    const height = myDiv.offsetHeight;
    // console.log(`viewport height` + window.innerHeight);
    // console.log(`skill div height:` + height);
    if (window.innerHeight > height && !myDiv.classList.contains("sticky-skills")) myDiv.classList.add("sticky-skills");
    if (window.innerHeight < height && myDiv.classList.contains("sticky-skills")) myDiv.classList.remove("sticky-skills");
  }

  render () {
    return (
      <div>
        <Header />
        <div className="container-xxl px-0">
          {/* main-title div */}
          <div className="row align-items-center px-2 mx-auto main-title sticky">
            <div className="col-sm p-5">
              <p className="text-body-tertiary mb-1">Hi My name is </p>
              <h1 className="main-text p-0">Sri Harsha Mullapudi</h1>
              <h4>@HarshaBlaze</h4>
              <h5 className="pt-1 text-body-tertiary">I am a full stack developer with expertise in developing distributed systems applications</h5>
              <div className="pt-3 row">
                <h3 className="col-xl-3 text-nowrap">Let's <span className="text-success"><ReactRotatingText items={["Learn", "build", "grow"]} typingInterval="100" pause="2000" /></span></h3>
                <h3 className="col-xl-3"> together.</h3>
              </div>
            </div>
            <div className="col-sm p-md-5">
              <img src = {svg} alt="logo"/>
            </div>
          </div>
          {/* Skills div */}
          <div><img className="position-fixed container-xxl zindex" src={bgsvg}/></div>
          <div className="container-xxl pb-3 pt-4 px-3 bg-blur" id="skillsID">
            <div className="row mb-4">
              <div className="col-lg-8 mx-auto text-center">
                <h2 className="skills-title custom-font">My Skills</h2>
                <h6 className="mb-0">Web development expert with skills in 
                  <span style={{ color: "#6e6e73" }}> front-end, back-end, cloud,</span> and 
                  <span style={{ color: "#6e6e73" }}> automation</span>
                </h6>
              </div>
            </div>
            {/* card */}
            <SkillsCard />
            {/* card end */}
          </div>
          {/* test nav pills */}
          <div className="container-xxl pb-3 pt-4 bg-blur">
            <div className="row mb-4">
              <div className="col-lg-8 mx-auto text-center">
                <h2 className="custom-font skills-title">My Background</h2>
                <h6 className="mb-0">
                  As a web developer I have excellent
                  <span style={{ color: "#6e6e73" }}> academic credentials </span>and
                  <span style={{ color: "#6e6e73" }}> proven work experience.</span>
                </h6>
              </div>
            </div>
            <ul className="nav nav-pills nav-pills-bg-soft justify-content-sm-center mb-4 px-3" id="course-pills-tab" role="tablist">
              
              <li className="nav-item me-2 me-sm-5" role="presentation">
                <button className="nav-link mb-2 mb-md-0 active" id="course-pills-tab-1" data-bs-toggle="pill" data-bs-target="#course-pills-tabs-1" type="button" role="tab" aria-controls="course-pills-tabs-1" aria-selected="true">Work Experience</button>
              </li>
              
              <li className="nav-item me-2 me-sm-5" role="presentation">
                <button className="nav-link mb-2 mb-md-0" id="course-pills-tab-2" data-bs-toggle="pill" data-bs-target="#course-pills-tabs-2" type="button" role="tab" aria-controls="course-pills-tabs-2" aria-selected="false" tabindex="-1">Education</button>
              </li>
              
              {/* <li className="nav-item me-2 me-sm-5" role="presentation">
                <button className="nav-link mb-2 mb-md-0" id="course-pills-tab-3" data-bs-toggle="pill" data-bs-target="#course-pills-tabs-3" type="button" role="tab" aria-controls="course-pills-tabs-3" aria-selected="false" tabindex="-1">Projects</button>
              </li> */}
              
              <li className="nav-item me-2 me-sm-5" role="presentation">
                <button className="nav-link mb-2 mb-md-0" id="course-pills-tab-4" data-bs-toggle="pill" data-bs-target="#course-pills-tabs-4" type="button" role="tab" aria-controls="course-pills-tabs-4" aria-selected="false" tabindex="-1">Publications</button>
              </li>
              
              <li className="nav-item me-2 me-sm-5" role="presentation">
                <button className="nav-link mb-2 mb-md-0" id="course-pills-tab-5" data-bs-toggle="pill" data-bs-target="#course-pills-tabs-5" type="button" role="tab" aria-controls="course-pills-tabs-5" aria-selected="false" tabindex="-1">Awards and Recognitions</button>
              </li>
            </ul>
            
            <div className="tab-content" id="course-pills-tabContent">
              <div className="tab-pane fade active show" id="course-pills-tabs-1" role="tabpanel" aria-labelledby="course-pills-tab-1">
                <ExperienceCard />
              </div>
              <div className="tab-pane fade" id="course-pills-tabs-2" role="tabpanel" aria-labelledby="course-pills-tab-2">
                <EducationCard />
              </div>
              <div className="tab-pane fade" id="course-pills-tabs-3" role="tabpanel" aria-labelledby="course-pills-tab-3">
                
              </div>
              <div className="tab-pane fade" id="course-pills-tabs-4" role="tabpanel" aria-labelledby="course-pills-tab-4">
                <PublicationCard />
              </div>
              <div className="tab-pane fade" id="course-pills-tabs-5" role="tabpanel" aria-labelledby="course-pills-tab-5">
                <RecognitionCard />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;