import React, { Component } from 'react';
import './Skills.css';

// let sourceHeight = 0;

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function setHeight() {
  await sleep(100);
  var ele1 = document.getElementById("custom-card-height");
  var ele2 = document.getElementById("card-height-receiver");
  ele2.style.height = ele1.offsetHeight + "px";
  // sourceHeight = ele1.offsetHeight;
  ele2.style.width = "auto";
  var ele3 = document.getElementById("center-img");
  ele3.style.margin = "auto";
}

async function handleHeight () {
  for(var i=0; i< 10; i++) {
    await setHeight();
    // console.log(`set Height Called. setHeight: ${sourceHeight}`);
    await sleep(1000);
  }
}

class Skills extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0
    }
  }
  componentDidMount() {
    if (document.getElementById("custom-card-height")) handleHeight();
  }
  render() {
    const skill = this.props.skill;
    const index = this.props.index;
    return (
      <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
        <div className="card card-sm h-100">
          <div className="p-2" id={skill.fileName==="devops"?"center-img":""}>
            <img className="card-img card-img-top"
              id = {skill.fileName === "devops"?"card-height-receiver":"custom-card-height"}
              src={require(`../../assets/svg/${skill.fileName}.svg`)}
              alt="img" />
          </div>
          <div className="card-body m-1 skill-card">
            <div className="card-title">
              <h5 className="text-center text-primary-emphasis">{skill.title}</h5>
            </div>
            <div className='text-center'>
              {skill.softwareSkills.map((logo) => {
                return (
                  <li className="skill-logo-inline ps-1" name={logo.skillName}>
                    {logo.fontAwesomeClassname && (
                      <p
                        className="iconify custom-icon-size"
                        data-icon={logo.fontAwesomeClassname}
                        style={logo.style}
                        data-inline="false"
                      ></p>
                    )}
                  </li>
                )
              })}
            </div>
            {skill.skills.map((skill) => {
              return <p className='text-secondary text-align-justify'>{skill}</p>
            })}
            {
              skill.imageAttribution && <small className="image-attribution">
                <a className='image-attribution' target="_blank" rel="noreferrer" href={skill.imageLink} style={{ textDecoration: "none", fontSize: "10px" }}>Image by ©{skill.imageAttribution}</a>
              </small>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Skills;
