import React, { Component } from "react";
import "./EducationCard.css";
import { degrees } from "../../props";
import { Accordion } from "baseui/accordion";
import Education from "../../components/education/Education";

class EducationCard extends Component {
  render() {
    return (
      <div className="education-accord">
        <Accordion>
          {degrees.degrees.map((degree, index) => {
            return <Education degree={degree} index={index} />
          })}
        </Accordion>
      </div>
    );
  }
}

export default EducationCard;
