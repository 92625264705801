import React, { Component } from "react";
import "./ExperienceCard.css";
import { experience } from "../../props";
import { Accordion, Panel } from "baseui/accordion";
import Experience from "../../components/experience/Experience";

class ExperienceCard extends Component {
  render() {
    return (
      // <ExperienceAccordion sections={experience["sections"]} theme={theme} />
      <div className="experience-accord">
        <Accordion>
          {experience.sections.map((section) => {
            return (
              section["experiences"].map((experience, index) => {
                return (
                  <Experience index={index} totalCards={section["experiences"].length} experience={experience} title={section["title"]} />
                );
              })
            );
          })}
        </Accordion>
      </div>
    );
  }
}

export default ExperienceCard;
