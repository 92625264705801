import React, { Component } from "react";
import "./RecognitionCard.css";
import { recognition } from "../../props";
import { Accordion } from "baseui/accordion";
import Recognition from "../../components/recognition/Recognition";

class RecognitionCard extends Component {
  render() {
    return (
      <div className="publication-accord">
        <Accordion>
          {recognition.data.map((recognition, index) => {
            return <Recognition recognition={recognition} index={index} />
          })}
        </Accordion>
      </div>
    );
  }
}

export default RecognitionCard;
